import { pushToDataLayer } from '@scandiweb/gtm/src/util/push';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';

import App from 'Component/App';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import 'Style/main';

// let's register service-worker
waitForPriorityLoad().then(
    /** @namespace Theme/Render/waitForPriorityLoad/then */
    () => {
        if ('serviceWorker' in navigator) {
            const swUrl = '/service-worker.js';
            navigator.serviceWorker.register(swUrl, { scope: '/' });
        }

        if (window.metaHtml) {
            const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
            Object.values(doc?.head?.children || {}).forEach((node) => document.head.appendChild(node));
        }
    },
);

if (module.hot) {
    module.hot.accept();
}

/* @namespace Theme/Render/HotApp */
export function HotApp() {
    return <App />;
}

/* @namespace Theme/Render/docReady */
export function docReady(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

docReady(() => {
    window.onDitAnalytics = () => {
        pushToDataLayer({ event: 'onAnalytics' });
    };

    render(<HotApp />, document.getElementById('root'));
});
